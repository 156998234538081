export default (src) => {
  class Facebook extends window.HTMLElement {
    connectedCallback () {
      this.innerHTML = `
      <section class="facebook-embed">
        <div
          class="fb-page"
          data-href="https://www.facebook.com/WarringtonWolvesVolleyballClub"
          data-tabs="timeline"
          data-width="900"
          data-height="900"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="false">
            <blockquote
              cite="https://www.facebook.com/WarringtonWolvesVolleyballClub"
              class="fb-xfbml-parse-ignore">
                <a href="https://www.facebook.com/WarringtonWolvesVolleyballClub">
                  Warrington Wolves Volleyball Club Facebook Page
                </a>
            </blockquote>
        </div>
        </section>
      `
    }
  }

  return Facebook
}
