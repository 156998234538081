import fbIcon from '../../img/fb.png'
import twitterIcon from '../../img/twitter.png'
import emailIcon from '../../img/email.png'
import clubLogo from '../../img/logo.jpeg'
import volleyballEnglandLogo from '../../img/volleyball-england.png'

export default () => {
  class Footer extends window.HTMLElement {
    constructor () {
      super()

      this.items = [
        {
          icon: fbIcon,
          link: 'https://www.facebook.com/WarringtonWolvesVolleyballClub'
        },
        {
          icon: twitterIcon,
          link: 'https://twitter.com/WW_VolleyBall'
        },
        {
          icon: emailIcon,
          link: 'mailto:contact@wolvesvolleyball.net'
        },
        {
          icon: volleyballEnglandLogo,
          link: 'https://www.volleyballengland.org/'
        },
        {
          icon: clubLogo
        }
      ]
    }

    connectedCallback () {
      const items = this.items.map((item) => {
        return item.link
          ? `
          <li class="nav-item">
            <a href="${item.link}" target="_blank" rel="noopener">
              <img src="${item.icon}" class="img-fluid image--hero" />
            </a>
          </li>
          `
          : `
          <li class="nav-item">
            <img src="${item.icon}" class="img-fluid image--hero" />
          </li>
          `
      }).join('')
      this.innerHTML = `
      <footer>
        <ul>${items}</ul>
        <p>
          Icons by <a href="https://icons8.com/" target="_blank" rel="noopener">icons8</a>.
        </p>
      </footer>
      `
    }
  }

  return Footer
}
