import clubLogo from '../../img/logo.jpeg'
import hogRoastImg from '../../img/cheeky-swine-hog-roast.jpg'

export default () => {
  class Header extends window.HTMLElement {
    connectedCallback () {
      this.innerHTML = `
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark header">
          <a class="navbar-brand logo" href="#">
            <img src="${clubLogo}" alt="">
          </a>
          <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
            <ul class="navbar-nav ml-auto">
                <a class="navbar-brand logo" href="https://cheekyswinehogroast.com/" target="_blank" rel="noopener">
                  <img src="${hogRoastImg}" alt="">
                </a>
            </ul>
          </div>
        </nav>
        `
    }
  }

  return Header
}
