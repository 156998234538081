import './base.css'
import './base.js'

import action from '../../img/action.png'

import HeroImage from '../components/hero-image'
import Facebook from '../components/facebook'
import SponsorPanel from '../components/sponsor-panel'

window.customElements.define('wolves-hero-image', HeroImage(action))
window.customElements.define('wolves-facebook-embed', Facebook())
window.customElements.define('wolves-sponsor-panel', SponsorPanel())
