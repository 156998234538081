export default (src) => {
  class HeroImage extends window.HTMLElement {
    connectedCallback () {
      this.innerHTML = `
      <img src="${src}" class="img-fluid image--hero" />
      `
    }
  }

  return HeroImage
}
