import hogRoastImg from '../../img/cheeky-swine-hog-roast.jpg'

export default () => {
  class Panel extends window.HTMLElement {
    connectedCallback () {
      this.innerHTML = `
        <a href="https://cheekyswinehogroast.com/" target="_blank" rel="noopener">
          <img src="${hogRoastImg}" class="img-fluid image--hero image--sponsor"></img>
        </a>
        `
    }
  }

  return Panel
}
