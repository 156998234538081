export default () => {
  class Header extends window.HTMLElement {
    constructor () {
      super()

      this.items = [
        {
          title: 'Home',
          link: '/index.html'
        },
        {
          title: 'Training',
          link: '/training.html'
        },
        {
          title: 'Teams',
          items: [
            {
              title: 'Warrington Wolves Men\'s A',
              link: '/mens-a.html'
            },
            {
              title: 'Warrington Wolves Men\'s B',
              link: '/mens-b.html'
            },
            {
              title: 'Warrington Wolves Ladies',
              link: '/ladies.html'
            },
            {
              title: 'Warrington Wolves Mixed',
              link: '/mixed.html'
            }
          ]
        },
        {
          title: 'Fixtures and Results',
          link: '/fixtures-and-results.html'
        },
        {
          title: 'Gallery',
          link: '/gallery.html'
        },
        {
          title: 'Downloads',
          items: [
            {
              title: 'Fixtures calendar (ICS)',
              link: '/assets/fixtures.ics'
            },
            {
              title: 'COVID-19 Risk Assessment',
              link: '/assets/covid-19-risk-assessment.doc'
            },
            {
              title: 'Club Constitution',
              link: '/assets/constitution.pdf'
            },
            {
              title: 'Child Protection Policy',
              link: '/assets/child-protection-policy.pdf'
            }
          ]
        }
      ]
    }

    connectedCallback () {
      const items = this.items.map((item, index) => {
        if (item.items) {
          const innerItems = item.items.map((innerItem) => {
            return `
            <a class="dropdown-item" href="${innerItem.link}">${innerItem.title}</a>
            `
          }).join('')
          return `
          <li class="nav-item">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            ${item.title}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            ${innerItems}
            </div>
          </li>
          `
        } else {
          return `
          <li class="nav-item">
            <a class="nav-link" href="${item.link}">${item.title}</a>
          </li>
          `
        }
      }).join('')
      this.innerHTML = `
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark subheader">
          <a class="navbar-brand" href="#">Warrington Wolves <br />Volleyball Club</a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">${items}</ul>
          </div>
        </nav>
        `
    }
  }

  return Header
}
